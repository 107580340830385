<app-navbar-one></app-navbar-one>

<div class="error-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Page Not Found</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>404 Error</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="error-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-md-2">
                <div class="error-img">
                    <img src="assets/img/404.png" alt="error image">
                </div>
            </div>
        </div>

        <div class="theme-button text-center">
            <a routerLink="/" class="default-btn">Go To Home</a>
        </div>
    </div>
</div>