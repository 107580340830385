import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-navbar-one',
  templateUrl: './navbar-one.component.html',
  styleUrls: ['./navbar-one.component.scss']
})
export class NavbarOneComponent implements OnInit {

  constructor(private router: Router,private route: ActivatedRoute,) { }

  ngOnInit(): void {
  }
  servicedetail(servicedetail: any) {
    this.router.navigate(['/service-details/'+ servicedetail]);
    setTimeout(() => {
      location.reload();
    }, 100);
  }
}
