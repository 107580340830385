import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class ContactformService {
  public serviceUrl = environment.serviceUrl;
  constructor(public http: HttpClient) { }

  public contactus(body: any) {
    const url = `${this.serviceUrl}/SP/ContactUs_Operations`;
    return this.http.post<any>(url, body).pipe(
      tap(d => console.log('test', d)),
      map(d => (d.Status_cd === "1") ? d.ds.Table : d)
    )
  }



  public async Token(body: any): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Token`;

        return this.http.post<any>(url, body)
          .pipe(map((d: any) => (d.code == "1" ? d : "")))
          .subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public SwalSuccess(msg: string, heading = 'Success!') {
    Swal.fire({
      title: heading,
      text: msg,
      icon: 'success',
      confirmButtonColor: '#0f4a21'
    })
  }
  public SwalWarning(msg: any, heading = 'Warning') {
    Swal.fire(msg, heading, 'warning');
  }
  public SwalError(msg: any, heading = 'Error') {
    Swal.fire(msg, heading, 'error');
  }

}