<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-9">
                <div class="header-left-text">
                    <ul>
                        <li><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> About us</a></li>
                        <li><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> FAQ</a></li>
                        <li><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Contact us</a></li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-3">
                <div class="header-right text-end">
                    <div class="header-social">
                        <ul>
                            
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo-two.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo" style="width: 85px;">    <img src="assets/img/logo2.png" alt="logo" style="width: 250px;">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li> -->
                        <li class="nav-item"> <a (click)="servicedetail('1')" class="nav-link" >GST <i class="icofont-arrow"></i></a></li>
                        <li  class="nav-item"><a   (click)="servicedetail('2')" class="nav-link" >Income Tax <i class="icofont-arrow"></i></a></li>
                                    <li  class="nav-item"><a  (click)="servicedetail('3')" class="nav-link" >E-Way Bills<i class="icofont-arrow"></i></a></li>
                                    <li  class="nav-item"><a  (click)="servicedetail('4')" class="nav-link" >Tax Deduction at Source <i
                                                class="icofont-arrow"></i></a></li>
                                    <li  class="nav-item"><a  (click)="servicedetail('5')" class="nav-link" >Bank Loans<i class="icofont-arrow"></i></a></li>
                                    <li  class="nav-item"><a (click)="servicedetail('6')" class="nav-link" > Accounting<i class="icofont-arrow"></i></a></li>
                                    <li  class="nav-item"><a (click)="servicedetail('7')" class="nav-link" >MCA Services<i class="icofont-arrow"></i></a></li>
                                    <li  class="nav-item"><a (click)="servicedetail('11')" class="nav-link" >Payroll<i class="icofont-arrow"></i></a></li>
                        <!-- <li class="nav-item">
                            <a  class="nav-link " routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" routerLink="/service" >Services <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                               

                                    <li><a href="#" (click)="servicedetail('1')" class="nav-link" >Goods & Services Tax <i class="icofont-arrow"></i></a>
                                    </li>
                                    <li><a   (click)="servicedetail('2')" class="nav-link" >Income Tax <i class="icofont-arrow"></i></a></li>
                                    <li><a  (click)="servicedetail('3')" class="nav-link" >E-Way Bills<i class="icofont-arrow"></i></a></li>
                                    <li><a  (click)="servicedetail('4')" class="nav-link" >Tax Deduction at Source <i
                                                class="icofont-arrow"></i></a></li>
                                    <li><a  (click)="servicedetail('5')" class="nav-link" >Bank Loans<i class="icofont-arrow"></i></a></li>
                                    <li><a (click)="servicedetail('6')" class="nav-link" > Accounting<i class="icofont-arrow"></i></a></li>
                                    <li><a (click)="servicedetail('7')" class="nav-link" >MCA Services<i class="icofont-arrow"></i></a></li>
                                    <li><a  (click)="servicedetail('8')" class="nav-link" >Foreign Direct Investments <i
                                                class="icofont-arrow"></i></a></li>
                                    <li><a  (click)="servicedetail('9')" class="nav-link" >Miscellaneous Services<i class="icofont-arrow"></i></a>
                                    </li>
                                    <li><a (click)="servicedetail('10')" class="nav-link" >Savdhan Businessman<i class="icofont-arrow"></i></a>
                                    </li>
                                    <li><a (click)="servicedetail('11')" class="nav-link" >Payroll Services<i class="icofont-arrow"></i></a></li>
        
        
                                
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Portfolio <i class="flaticon-right"></i></a>

                      
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog One</a></li>

                                <li class="nav-item"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Two</a></li>

                                <li class="nav-item"><a routerLink="/blog-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Three</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->
                    </ul>

                 <!--    <div class="other-option">
                        <div class="search-bar">
                            <i class="flaticon-search search-icon"></i>
                            <div class="search-form">
                                <form>
                                    <input type="text" placeholder="Search" class="search-input">
                                    <button type="submit">
                                        <i class="flaticon-search search-btn"></i>
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div class="sidebar-toggle">
                            <button type="button" class="btn btn-demo toggle-button navbar-toggle" data-bs-toggle="modal" data-bs-target="#sidebar-right">
                                <i class="flaticon-list"></i>
                            </button>
                        </div> 
                    </div>-->
                </div>
            </nav>
        </div>
    </div>
</div>