import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-three',
  templateUrl: './blog-three.component.html',
  styleUrls: ['./blog-three.component.scss']
})
export class BlogThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
