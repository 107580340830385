<app-navbar-one></app-navbar-one>

<div class="about-title" style="background-image: url(assets/img/pattern.png);opacity: 0.9;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>About Us</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/2.jpg" alt="about image">
                </div>
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>GST-Bhaswa is a leading provider of online GST and Income Tax filing</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <p>GST-Bhaswa is a leading provider of online GST and Income Tax filing services that has revolutionized the traditional approach to tax compliance. The company understands the importance of accurate and timely tax filing and is committed to providing its clients with hassle-free, zero-error tax returns.</p>
                    <!-- <div class="theme-button">
                       <a routerLink="/about" class="default-btn">Read More</a>
                    </div> -->
                </div>
            </div>
                 
         
        </div>
        <div class="row align-items-center pt-5"> 
            <div class="col-lg-12">
                <div class="">
                 

                  
                      <p>With its state-of-the-art fully-automated BPO, GST-Bhaswa ensures that its clients receive the highest quality service with no room for error. Its team of experienced CAs and Tax Professionals is always ready to provide expert advice on all tax-related matters. </p>
                        <p>  GST-Bhaswa's value-added services, such as its call center and mobile app, make it easy for clients to stay up-to-date with their tax returns, while its toll-free multi-lingual customer care ensures that clients receive prompt and efficient support. </p>
                            <p>  The company's commitment to data security is evident in its adoption of international industry standards. And, with its affordable pricing starting at just 315/- per return, GST-Bhaswa is the ideal partner for businesses of all sizes looking for a trusted, low-cost, and technology-driven tax filing solution. </p>
                                <p> In addition to tax filing, GST-Bhaswa also offers a range of other services, including GST registration, trademark registration, bookkeeping and outsourcing, company formation and registration, FDI compliance, company annual filing, and FSSAI registration. </p>
                                    <p> With over 10 years of experience providing online CA services, GST-Bhaswa has the expertise to provide businesses with a range of solutions to meet their unique needs. The company offers company registration services, GST registration and filing services, accounting and bookkeeping services, auditing and assurance services, and income tax return filing services. </p>
                                        <p> GST-Bhaswa's vision is to help businesses establish and grow in India by providing tax and accounting solutions across the globe, with a focus on e-commerce growth. Its mission is to create a simple and excellent quality platform where clients can find all the solutions they need for their accounting and tax needs. 
                        </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="theory-section theory-style-two">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>My Theory is Customer Service is First</h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>

                    <div class="signature-box">
                        <img src="assets/img/signature.png" alt="signature">
                        <p>Babatunde Smithi</p>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/theory-img.png" alt="theory image">
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="video-style-two video-style-three">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                    <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i class="bx bx-play"></i></a>
                    <p>Watch our video</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team Member</span>
            <h2>Meet Our Expert Team Member</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Jerry Hudson</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Juho Hudson</h3>
                            <p>Research Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Sarah Tylor</h3>
                            <p>Marketing Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Aikin Ward</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="service-section service-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 service-img"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Servicesfor Them</h2>
                    <ul>
                        <li><i class="flaticon-checkmark"></i>Building Networking</li>
                        <li><i class="flaticon-checkmark"></i>Software Development</li>
                        <li><i class="flaticon-checkmark"></i>Business Strategy</li>
                        <li><i class="flaticon-checkmark"></i>Business Management</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="progress-section progress-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 p-0">
                <div class="progress-text">
                    <h2>We Always Try to be the Best Support to Our Clients as Possible</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>
                    
                    <div class="progress-inner">
                        <p>Software Development</p>
                        <span>70%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                        
                    <div class="progress-inner">
                        <p>Digital Marketing</p>
                        <span>85%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div class="progress-inner">
                        <p>Business Management</p>
                        <span>95%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 progress-image"></div>
        </div>
    </div>
</div> -->

<app-contactform></app-contactform>