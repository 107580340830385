<!-- <div class="newsletter-section">
    <div class="container">
        <div class="newsletter-area">
            <h2>Subscribe Our Newsletter</h2>

            <div class="col-lg-6 offset-lg-3 p-0">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                    <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                </form>
            </div>
            <img src="assets/img/shapes/2.png" class="subscribe-shape" alt="shape">
        </div>
    </div>
</div> -->

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo2.png" alt="logo" style="border-radius: 5px;"></a>
                    </div>
                    <p>GST-Bhaswa Is Platform Ready To Serve Around 1 Lakh Tax Payers Across The Country</p>
                    <div class="footer-social">
                        <a href="#" target="_blank"><i class="flaticon-facebook"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-twitter"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-instagram"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-linkedin"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Services</h3>

                    <ul>
                        <li><a routerLink="/service"><i class="flaticon-right"></i> Tax Filing</a></li>
                        <li><a routerLink="/service"><i class="flaticon-right"></i> GST Registration</a></li>
                        <li><a routerLink="/service"><i class="flaticon-right"></i> Trademark Registration</a></li>
                        <li><a routerLink="/service"><i class="flaticon-right"></i> Company Formation & Registration</a></li>
                        <li><a routerLink="/service"><i class="flaticon-right"></i> FDI Compliance</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a routerLink="/"><i class="flaticon-right"></i> Home</a></li>
                        <li><a routerLink="/about"><i class="flaticon-right"></i> About Us</a></li>
                        <li><a routerLink="/service"><i class="flaticon-right"></i> Services</a></li>
                     
                        <li><a routerLink="/faq"><i class="flaticon-right"></i> FAQ</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Contact Us</h3>

                    <ul>
                        <li><i class="flaticon-right"></i>6-3-866/1/1,2 & 3, Bhaswa Business Park,<Br> Greenlands, Hyderabad, TG-500 016 </li>
                        <li><a href="#"><i class="flaticon-right"></i> 1800 121 8129</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> 1800 121 8129</a></li>
                        <!-- <li><a href="mailto:hello@ezlu.com"><i class="flaticon-right"></i> hello@ezlu.com</a></li> -->
                        <li><a href="#"><i class="flaticon-right"></i> info@gstbhaswa.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                

                <div class="col-md-12 lh-1 text-end">
                    <ul>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- <div class="right-modal">
    <div class="modal fade right" id="sidebar-right" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 p-0">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/home-two"><img src="assets/img/modal/2.jpg" alt="home one"></a>
                                           <h3><a routerLink="/home-two">Home Two</a></h3>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/about"><img src="assets/img/modal/3.jpg" alt="home one"></a>
                                           <h3><a routerLink="/about">About</a></h3>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/service"><img src="assets/img/modal/4.jpg" alt="home one"></a>
                                           <h3><a routerLink="/service">Service</a></h3>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/portfolio"><img src="assets/img/modal/5.jpg" alt="home one"></a>
                                           <h3><a routerLink="/portfolio">Portfolio</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->