import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactformService } from './contactform.service';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss']
})
export class ContactformComponent {
  contactForm: FormGroup;
  isSubmitted = false;
  submitvalid: boolean;
  constructor(private fb: FormBuilder,
    public service: ContactformService) {

  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required,
        Validators.pattern(/^([0-9a-zA-Z]{2}([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/)])],
      phone:  ['', [Validators.required, Validators.minLength(10), Validators.pattern(/^[6-9]{1}[0-9]{9}$/)]],
      
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
  }
  get f() { return this.contactForm.controls; }
  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get phone() { return this.contactForm.get('phone'); }
  get message() { return this.contactForm.get('message'); }

  submitForm() {
    if (this.contactForm.invalid) {
      return
      } else {
        this.submitvalid = true;
      console.log(this.contactForm.value);
      let data = this.contactForm.value
      let body = {

        "json": JSON.stringify({
          "Name": data.name,
          "Email": data.email,
          "Message": data.message,
          "Mobile": data.phone
        }),
        Condition: "Insert_ContactUs",

      }
      // this.service.contactus(body).pipe(first()).subscribe(res => {
      //     console.log(res);
      // })

      this.service.contactus(body).subscribe((res: any) => {
        console.log(res)
     if (res) {
     this.submitvalid = false;
  
              this.contactForm.reset();
            
             this.service.SwalSuccess('We will get back to you soon', 'Thank You');
  
            } else {
           this.service.SwalError('Something went wrong', 'Error');
  
  
      }
      })
    
    
  }

}

}

