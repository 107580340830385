<app-navbar-one></app-navbar-one>

<div class="contact-title" style="background-image: url(assets/img/pattern.png);opacity: 0.9;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Contact Us</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pt-100">
    <div class="container">
        <div class="row contact-box">
            <div class="col-lg-6 col-md-6">
           
                <div class="contact-card">
                    <div>   <i class="flaticon-maps-and-flags"></i></div>
                    <h3>Location</h3>
                    <ul>
                        <li>6-3-866/1/1,2 & 3, <br>Bhaswa Business Park,<Br> Greenlands, Hyderabad, <br>TG-500 016,</li>
                    
                    </ul>
                    <i class="flaticon-envelope"></i>
                    <!-- <h3>Email</h3>
                    <ul>
                        <li>info@gstbhaswa.com/</li>
                        <li></li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-call"></i>
                    <h3>Phone</h3>
                    <ul>
                       <b> Give a Missed Call on</b> 80 10 109 108<br>
<b>Call:</b> 040-4855 2692
<br><B> Toll Free:</B>1800 1218 129 <br>
<b>Mobile WhatsApp:</b> 90 30 30 77 23

                     
                       
                    </ul>
                </div>
            </div>

           
        </div>
    </div>
</div>

<app-contactform></app-contactform>