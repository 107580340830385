<app-navbar-one></app-navbar-one>

<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Empowering Financial Excellence</h1>
                    <p> We help identify tax deductions, credits, and other tax planning strategies that can help individuals and organizations minimize their tax liabilities and maximize their tax efficiency</p>

                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Get Started</a>
                        <!-- <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="video-btn popup-vimeo"><i class="bx bx-play"></i> Watch video</a> -->
                    </div>
                </div>

                <div class="banner-image">
                    <img src="assets/img/banner/1.png" alt="banner image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="video-section pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="video-text">
                    <a href="#" class="popup-vimeo"><i class='bx bx-play'></i></a>
                    <p>Watch our video and know more about us</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>GST-Bhaswa is platform ready to serve around 1 lakh Tax Payers across the country</h2>
                    <p>                        We have made the Tax Filing process easy, simple. You need a permanent, trusted, low-cost and a technology-driven partner for filing the returns. So the natural choice is GST-Bhaswa. </p>
                    <!-- <div class="signature-box">
                        <img src="assets/img/signature.png" alt="signature">
                        <p>Babatunde Smithi</p>
                        <span>Founder and CEO</span>
                    </div> -->
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/theory-img.png" alt="theory image">
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/2.jpg" alt="about image">
                </div>
            </div>

            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>GST Bhaswa is a leading provider of online GST and Income Tax filing</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <p>GST-Bhaswa is a leading provider of online GST and Income Tax filing services that has revolutionized the traditional approach to tax compliance. The company understands the importance of accurate and timely tax filing and is committed to providing its clients with hassle-free, zero-error tax returns.</p>
                    <div class="theme-button">
                       <a routerLink="/about" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="team-section pt-100 pb-70">
<div class="demo">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>What We do?</span>
            <h2>Services We provide</h2>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="pricingTable">
                    <div class="pricingTable-header">
                        <h3 class="title">Goods and Service Tax</h3>
                        <!-- <div class="price-value">$10.99 per month</div> -->
                    </div>
                 
                        <ul class="pricing-content"><li><i class="bx bx-check"></i>GST Health Check (Analysis of past &amp; pending issues)</li>
                            <li><i class="bx bx-check"></i>Resolution of Pending Problem</li>
                            <li><i class="bx bx-check"></i>GST Return filing for all Categories of Tax Payers</li>
                                <li><i class="bx bx-check"></i>Tax Loss Detection</li>
                                <li><i class="bx bx-check"></i>Lost Tax Recovery Services</li>
                                <li><i class="bx bx-check"></i>ZERO TAX Leakage (Identification of Unclaimed Taxes)</li>
                                <li><i class="bx bx-check"></i>New GST Registration</li><li><i class="bx bx-check"></i>GST Annual returns</li>
                                <li><i class="bx bx-check"></i>GST Audit</li>
                                <li><i class="bx bx-check"></i>Fake GSTIN identification</li>
                                <li><i class="bx bx-check"></i>GST Dept Show Cause Notice Reply &amp; Problem Resolution</li>
                                <li><i class="bx bx-check"></i>GST tax planning</li>
                                <li><i class="bx bx-check"></i>GST-EWB Reconciliation</li>
                            </ul>
                     
                   
                    <a href="#" routerLink="/contact" class="pricingTable-signup">Contact Us Now</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="pricingTable blue">
                    <div class="pricingTable-header">
                        <h3 class="title">Income Tax</h3>
                        <!-- <div class="price-value">$20.99 per month</div> -->
                    </div>
                    <ul  class="pricing-content"><li> <i class="bx bx-check"></i>Income Tax Health Check</li>
                        <li> <i class="bx bx-check"></i>(Analysis of past &amp; pending issues)</li>
                        <li> <i class="bx bx-check"></i>Resolution of Pending Problem</li>
                        <li> <i class="bx bx-check"></i>Income Tax Return filing for all &nbsp;Categories of Tax Payers</li>
                        <li> <i class="bx bx-check"></i>Income Tax Audit</li>
                        <li><i class="bx bx-check"></i>Advice of PropertyPurchase &amp; Sale</li>
                        <li><i class="bx bx-check"></i>Capital Gains Tax Planning on Sale of Property/Shares</li>
                        <li><i class="bx bx-check"></i>Income Tax Planning</li>
                        <li><i class="bx bx-check"></i>Statement of Financial Transaction</li>
                        <li><i class="bx bx-check"></i>Income Tax Assessments</li>
                        <li><i class="bx bx-check"></i>Show Cause Notice Reply &amp; Problem Resolution</li>
                        <li><i class="bx bx-check"></i>PAN Card &amp; Correction</li>
                        <li><i class="bx bx-check"></i>PAN-Aadhaar linking</li>
                        <li><i class="bx bx-check"></i>80G &amp; 12A Registration for Charitable Trust/Society/NGO</li>
                        <li><i class="bx bx-check"></i>Lower TDS Rate Certificate</li>
                        <li><i class="bx bx-check"></i>HUF Creation Deed &amp; Registration</li>
                        <li><i class="bx bx-check"></i>Trust Deed &amp; Registration</li></ul>
                    <a href="#" routerLink="/contact" class="pricingTable-signup">Contact Us Now</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="pricingTable pink">
                    <div class="pricingTable-header">
                        <h3 class="title">MCA Services</h3>
                        <!-- <div class="price-value">$30.99 per month</div> -->
                    </div>
                    <ul class="pricing-content"><li><i class="bx bx-check"></i>MCA Health Check(Analysis of past &amp; pending issues)</li><li><i class="bx bx-check"></i>Resolution of Pending Problem</li><li><i class="bx bx-check"></i>New Company / LLP Registration</li><li><i class="bx bx-check"></i>Statutory Audit</li><li><i class="bx bx-check"></i>Annual Return filing</li><li><i class="bx bx-check"></i>Capital Structure Planning</li><li><i class="bx bx-check"></i>Share Allotments Planning &amp; filings</li><li><i class="bx bx-check"></i>Director Identification No (DIN) Registration</li><li><i class="bx bx-check"></i>KYC services</li><li><i class="bx bx-check"></i>Charge Registration &amp; Satisfaction</li><li><i class="bx bx-check"></i>All Other MCA form filings</li><li><i class="bx bx-check"></i>Company Winding Up</li></ul>
                    <a href="#" class="pricingTable-signup" routerLink="/contact">Contact Us Now</a>
                </div>
            </div>
          
        </div>
    </div>
</div>

<div class="service-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 service-img"></div>

            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Services for Them</h2>
                    <ul>
                        <li><i class="flaticon-check"></i> Tax Return Filing:</li>
                        <li><i class="flaticon-check"></i> Auditing & Assurance</li>
                        <li><i class="flaticon-check"></i> Company Formation &amp; Registration</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<!-- <section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team Member</span>
            <h2>Meet Our Expert Team Member</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Jerry Hudson</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Juho Hudson</h3>
                            <p>Research Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Sarah Tylor</h3>
                            <p>Marketing Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Aikin Ward</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

 <div class="testimonial-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 pt-100 pb-100">
                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="slider-items">
                        <img src="assets/img/testimonial/1.png" alt="testimonial image">
                        <h3>Savdhan Businessman</h3>
                        
                       
                            <p  class="mt-3">   Many Accountants / Consultants committed errors in GST Returns, and businessmen are not aware of this.
                            </p>
                              <p> A decimal in the wrong place & a wrong alphabet in your customer’s GSTIN can ruin your peace.
                        </p>
                                <p>GST Returns once filed, cannot be revised.</p>
                       

                    </div>

                    <div class="slider-items">
                        <img src="assets/img/testimonial/1.png" alt="testimonial image">
                        <h3>Savdhan Businessman</h3>
                        
                       
                            <p class="mt-3">  Even if you have a competent Accountant using Tally or any other software, an Audit of each and every Invoice is a must to file a Zero-error Return.
                            </p>
                               <p> GST demands huge data. The challenge is to file 100% error-free returns.
                                <p>
                                <p>Shun traditional ways of handing out GST filing to small-time consultants.<p>
                       




                        
                    </div>

                    <div class="slider-items">
                        <img src="assets/img/testimonial/1.png" alt="testimonial image">
                        <h3>Savdhan Businessman</h3>
                       
                        <p>Leverage on advanced technology platform of as even a single mistake can cost you lakhs of rupees.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="testimonial-image"></div>
            </div>
        </div>
    </div>
</div> 
 <!-- <section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Portfolio</span>
            <h2>Latest Works For Clients</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/1.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Online Business</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/2.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Digital Marketing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/3.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Business Research</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/4.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Content Writing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/5.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/6.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>SEO Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="theme-button text-center">
            <a routerLink="/portfolio" class="default-btn">Load More</a>
        </div>
    </div>
</section>  -->


<section class="features-section pb-70 pt-100">
    <div class="container">
        <div class="section-title text-center">
            <span>
              Services Offered
                </span>
            <h2>
                GST-BHASWA Tax Return Seva Kendra
                </h2>
            <!-- <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> -->
        </div>

        <div class="row">
            <!-- <div class="col-lg-3 col-sm-4 flip-card">
              
                <div class="features-card feature-one flip-card-inner"  (click)="servicedetail('1')" >
                    <div class="flip-card-front">
                        <img src="assets/img/gst-icon.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Goods & Services Tax</h3></div>
                    <div class="flip-card-back"> <p>GST is a comprehensive tax system that has simplified the tax structure in India. 
                        To avail the benefits of GST, businesses need to comply with the rules and regulations laid down by the government</p> 
</div>

                </div>
            </div> -->
            <div class="col-lg-3 col-sm-4 ">
              
                <div class="features-card feature-one"  (click)="servicedetail('1')" >
                    <div class="">
                        <img src="assets/img/gst-icon.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Goods & Services Tax</h3></div>
                    <div class="flip-card-back"> <p>GST is a comprehensive tax system that has simplified the tax structure in India. To avail the benefits of GST, businesses need to comply with the rules and regulations laid down by the government</p> -->
</div>

                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-one"  (click)="servicedetail('2')" >
                    <img src="assets/img/income-taxes-icon.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Income Tax</h3>
                    <!-- <p>GST-Bhaswa offers online tax filing services for businesses and individuals, ensuring accurate and timely filing to avoid penalties and legal issues.</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-two" (click)="servicedetail('3')">
                    <img src="assets/img/truck-delivered-icon.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">E-Way Billsn</h3>
                    <!-- <p>The company assists clients in registering for GST, providing guidance and support throughout the process to ensure compliance with regulations.</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details"   class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-three"  (click)="servicedetail('4')" >
                    <img src="assets/img/tax-cut-icon.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Tax Deduction at Source</h3>
                    <!-- <p>GST-Bhaswa helps businesses register their trademarks, protecting their brand identity and preventing infringement</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-four "  (click)="servicedetail('5')" >
                    <img src="assets/img/loan.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Bank Loans</h3>
                    <!-- <p> The company provides bookkeeping and outsourcing services, freeing up businesses' time and resources to focus on core operations..</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-five" (click)="servicedetail('6')" >
                    <img src="assets/img/accounting.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Accounting</h3>
                    <!-- <p> GST-Bhaswa helps businesses set up and register their companies, providing advice and guidance on legal and regulatory requirements</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details"  class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-six"  (click)="servicedetail('7')">
                    <img src="assets/img/lock.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">MCA Services</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">   <a routerLink="/service-details"  class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-six"  (click)="servicedetail('8')" >
                    <img src="assets/img/business.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Foreign Direct Investments</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">   <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-six" (click)="servicedetail('10')" >
                    <img src="assets/img/truck-delivered-icon.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Savdhan Businessman</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">  <a routerLink="/service-details"  class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-six" (click)="servicedetail('9')">
                    <img src="assets/img/business-man.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Miscellaneous Services</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">  <a routerLink="/service-details"   class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="features-card feature-six" (click)="servicedetail('11')">
                    <img src="assets/img/cashback-inr-icon.png" alt="portfolio image"  width="70px"> 
                    <h3 class="mt-4">Payroll Services</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">    <a routerLink="/service-details"   class="default-btn2">Know More</a></div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <h2>Vision</h2>
                    <p>Our vision is to become the most trusted and reliable tax consulting company in the industry, providing innovative and effective solutions to help businesses achieve their financial goals</p>
                    <!-- <div class="progress-inner">
                        <p>Software Development</p>
                        <span>70%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div> -->
                    <h2>Mission</h2>
                    <p>Our mission is to provide our clients with the highest quality tax consulting services, customized to their specific needs and goals. We are committed to staying up-to-date with the latest tax laws and regulations, using the latest technology to provide efficient and effective solutions. We strive to build long-lasting relationships with our clients, based on trust, transparency, and exceptional service.</p>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Blog</span>
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/1.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">The next genaration IT will change the world</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-1.png" alt="blog author">
                            <a routerLink="/"><p>Aikin Ward</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/2.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>22 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Content writing demand day by day increasing</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-2.png" alt="blog author">
                            <a routerLink="/"><p>John Smith</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/3.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>23 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Don't buy a tech gift until you read these rules image</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-3.png" alt="blog author">
                            <a routerLink="/"><p>Sarah Tylor</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<app-contactform></app-contactform>