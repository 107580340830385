<app-navbar-one></app-navbar-one>

<div class="privacy-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Privacy & Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Privacy & Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="privacy-section pt-100">
    <div class="container">
        <p>Privacy Policy:</p>
        <p>We, as a tax consulting company based in India, are committed to protecting our clients' privacy and personal information in accordance with Indian laws and regulations. This privacy policy outlines how we collect, use, and protect client data.</p>
        <ol>
        <li>Collection of Personal Information: We collect personal information such as name, contact information, and financial information, from our clients to provide our services. This information is collected through our website, email, phone, or in-person meetings.</li>
        <li>Use of Personal Information: We use personal information to provide our tax consulting services to clients, to communicate with clients regarding their tax affairs, and to comply with legal obligations. We may also use personal information for marketing and promotional purposes.</li>
        <li>Protection of Personal Information: We take appropriate measures to protect client data and prevent unauthorized access, alteration, disclosure, or destruction. We use firewalls, encryption, and other security measures to safeguard client data.</li>
        <li>Sharing of Personal Information: We do not share client data with any third parties without their explicit consent, except when required by law or to provide our services.</li>
        <li>Retention of Personal Information: We retain client data for as long as necessary to provide our services and comply with legal obligations. We securely dispose of client data when it is no longer needed.</li>
        <li>Client Rights: Clients have the right to access their personal information, request corrections, and withdraw consent for the collection and use of their data. Clients may also request the deletion of their data, subject to legal and contractual obligations.</li>
        <li>Changes to Privacy Policy: We may update this privacy policy from time to time. Clients will be notified of any changes and provided with an updated policy.</li>
        </ol>
        <p>If you have any questions or concerns about our privacy policy or the collection and use of your personal information, please contact us.</p> 
    </div>
</div>