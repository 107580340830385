import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']

})
export class ServiceDetailsComponent implements OnInit {
  selectedID:any;
  serviesdetails:any = [
    {
        id: 1,
        serviceImg: 'assets/img/service-details.jpg',
        heading: 'Goods & Services Tax',
        paragraph:'Testbility',
        servicelist: '<ul class="pricing-content"><li>GST Health Check (Analysis of past &amp; pending issues)</li><li>Resolution of Pending Problem</li><li>GST Return filing for all Categories of Tax Payers</li><li>Tax Loss Detection</li><li>Lost Tax Recovery Services</li><li>ZERO TAX Leakage (Identification of Unclaimed Taxes)</li><li>New GST Registration</li><li>GST Annual returns</li><li>GST Audit</li><li>Fake GSTIN identification</li><li>GST Dept Show Cause Notice Reply &amp; Problem Resolution</li><li>GST tax planning</li><li>GST-EWB Reconciliation</li></ul>',
       
    },
    {
        id: 2,
        serviceImg: 'assets/img/income.jpg',
        heading: 'Income Tax',
        paragraph:'Testbility',
        servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>Income Tax Health Check</li><li><i class="bx bx-check"></i>(Analysis of past &amp; pending issues)</li><li><i class="bx bx-check"></i>Resolution of Pending Problem</li><li><i class="bx bx-check"></i>Income Tax Return filing for all &nbsp;Categories of Tax Payers</li><li><i class="bx bx-check"></i>Income Tax Audit</li><li><i class="bx bx-check"></i>Advice of PropertyPurchase &amp; Sale</li><li><i class="bx bx-check"></i>Capital Gains Tax Planning on Sale of Property/Shares</li><li><i class="bx bx-check"></i>Income Tax Planning</li><li><i class="bx bx-check"></i>Statement of Financial Transaction</li><li><i class="bx bx-check"></i>Income Tax Assessments</li><li><i class="bx bx-check"></i>Show Cause Notice Reply &amp; Problem Resolution</li><li><i class="bx bx-check"></i>PAN Card &amp; Correction</li><li><i class="bx bx-check"></i>PAN-Aadhaar linking</li><li><i class="bx bx-check"></i>80G &amp; 12A Registration for Charitable Trust/Society/NGO</li><li><i class="bx bx-check"></i>Lower TDS Rate Certificate</li><li><i class="bx bx-check"></i>HUF Creation Deed &amp; Registration</li><li><i class="bx bx-check"></i>Trust Deed &amp; Registration</li></ul>',
    },
    {
        id: 3,
        serviceImg: 'assets/img/eway.jpg',
        heading: 'E-Way Bills',
        paragraph:'Testbility',
        servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>E-Way Bill Registration</li><li><i class="bx bx-check"></i>Generation of EWB (in 10 minutes)</li><li><i class="bx bx-check"></i>Identification of illegal utilization of your EWB a/c &amp; problem resolution</li></ul>',
    },
    {
      id: 4,
      serviceImg: 'assets/img/service-details.jpg',
      heading: 'Tax Deduction at Source',
      paragraph:'Testbility',
      servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>TDS Health Check (Analysis of past &amp; pending issues)</li><li><i class="bx bx-check"></i>Resolution of Pending Problem</li><li><i class="bx bx-check"></i>TDS tax payment workings (Monthly)</li><li><i class="bx bx-check"></i>TDS Returns (Quarterly)</li><li><i class="bx bx-check"></i>Issue of TDS certificates</li><li><i class="bx bx-check"></i>TDS Assessments</li><li><i class="bx bx-check"></i>Show Cause Notice Reply &amp; Problem Resolution</li><li><i class="bx bx-check"></i>TRACES E-filing account</li></ul>',
  },
    {
        id: 5,
        serviceImg: 'assets/img/service-details.jpg',
        heading: 'Bank Loans',
        paragraph:'Bank Loans',
        servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>CMA preparation for new loans / renewals for CC / OD</li><li><i class="bx bx-check"></i>Stock Certification</li><li><i class="bx bx-check"></i>Net Worth certification</li><li><i class="bx bx-check"></i>CIBIL Report</li><li><i class="bx bx-check"></i>Loan Syndication through Associate firms</li></ul>',
    },
    {
      id: 6,
      serviceImg: 'assets/img/service-details.jpg',
      heading: 'Accounting',
      paragraph:'Accounting',
      servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>Book Keeping &amp; Bank Reconciliation(off-site &amp; on-site through ANYDESK)</li><li><i class="bx bx-check"></i>Balance Sheet Finalization</li><li><i class="bx bx-check"></i>Customer Receivables follow-up</li><li><i class="bx bx-check"></i>Customer &amp; Suppliers Ledger balance confirmation services</li><li><i class="bx bx-check"></i>Man Power Services for On-Site Accounting (full time)</li></ul>',
  },
  {
    id: 7,
    serviceImg: 'assets/img/service-details.jpg',
    heading: 'MCA Services',
    paragraph:'MCA Services',
    servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>MCA Health Check(Analysis of past &amp; pending issues)</li><li><i class="bx bx-check"></i>Resolution of Pending Problem</li><li><i class="bx bx-check"></i>New Company / LLP Registration</li><li><i class="bx bx-check"></i>Statutory Audit</li><li><i class="bx bx-check"></i>Annual Return filing</li><li><i class="bx bx-check"></i>Capital Structure Planning</li><li><i class="bx bx-check"></i>Share Allotments Planning &amp; filings</li><li><i class="bx bx-check"></i>Director Identification No (DIN) Registration</li><li><i class="bx bx-check"></i>KYC services</li><li><i class="bx bx-check"></i>Charge Registration &amp; Satisfaction</li><li><i class="bx bx-check"></i>All Other MCA form filings</li><li><i class="bx bx-check"></i>Company Winding Up</li></ul>',
},
{
  id: 8,
  serviceImg: 'assets/img/service-details.jpg',
  heading: 'Foreign Direct Investments',
  paragraph:'Foreign Direct Investmentsy',
  servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>FDI Advisory Services</li><li><i class="bx bx-check"></i>FDI Approvals</li><li><i class="bx bx-check"></i>RBI Compliances</li></ul>',
},
{
  id:9,
  serviceImg: 'assets/img/service-details.jpg',
  heading: 'Miscellaneous Services',
  paragraph:'Miscellaneous Services',
  servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>Partnership Deed drafting</li><li><i class="bx bx-check"></i>Partnership, Societies &amp; NGO Registration</li><li><i class="bx bx-check"></i>Rental Agreement drafting</li><li><i class="bx bx-check"></i>Business Purchase Agreements</li><li><i class="bx bx-check"></i>MSME Registration</li><li><i class="bx bx-check"></i>Labour licsense</li><li><i class="bx bx-check"></i>Food License</li><li><i class="bx bx-check"></i>PF &amp; ESI Registration</li>  <li><i class="bx bx-check"></i>PF &amp; ESI</li></ul>',
},
{
  id: 10,
  serviceImg: 'assets/img/service-details.jpg',
  heading: 'Savdhan Businessman',
  paragraph:'Savdhan Businessman',
  servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>Many Accountants / Consultants committed errors in GST Returns, and businessmen are not aware of this.</li><li><i class="bx bx-check"></i>A decimal in the wrong place &amp; a wrong alphabet in your customer&rsquo;s GSTIN can ruin your peace.</li><li><i class="bx bx-check"></i>GST Returns once filed, cannot be revised.</li><li><i class="bx bx-check"></i>Even if you have a competent Accountant using Tally or any other software, an Audit of each and every Invoice is a must to file a Zero-error Return.</li><li><i class="bx bx-check"></i>GST demands huge data. The challenge is to file 100% error-free returns.</li><li><i class="bx bx-check"></i>Shun traditional ways of handing out GST filing to small-time consultants.</li><li><i class="bx bx-check"></i>Leverage on advanced technology platform of as even a single mistake can cost you lakhs of rupees.</li></ul>',
},
{
  id: 11,
  serviceImg: 'assets/img/service-details.jpg',
  heading: 'Payroll Services',
  paragraph:'Payroll Services',
  servicelist: '<ul class="pricing-content"><li><i class="bx bx-check"></i>Payroll Accounting Services</li><li><i class="bx bx-check"></i>Bio-metric / Manual / Online attendance</li><li><i class="bx bx-check"></i>Salary Sheets</li><li><i class="bx bx-check"></i>Leave Management</li><li><i class="bx bx-check"></i>Salary Slip generation</li><li><i class="bx bx-check"></i>PF/ESI/TDS computations</li></ul>',
},


    
  ]  

  constructor(private route: ActivatedRoute,private router: Router) {
 
   }

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.selectedID =id;
  
  }
  servicedetail(servicedetail: any) {
    this.selectedID =servicedetail;
   
  }
}
