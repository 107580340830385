<app-navbar-one></app-navbar-one>

<div class="faq-title" style="background-image: url(assets/img/pattern.png);opacity: 0.9;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>FAQ</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>FAQ</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Frequently Asked Questions</h2>
                <div class="topic">
                    <div class="open">
                        <h3 class="question">1. What services does your company offer?</h3>
                        <span class="faq-t"></span>
                    </div>
                    <p class="answer">We offer a range of tax
                        consulting services, including tax filing, GST registration, trademark registration,
                        bookkeeping and outsourcing, company formation and registration, FDI
                        compliance, company annual filing, FSSAI registration, accounting and
                        bookkeeping, auditing and assurance, Import-Export Code, and income tax
                        return filing.
                        2.  Answer: 
                        3. Answer: 
                        4.  Answer: 
                        5.  Answer:
                        6.  Answer: 
                        7.  Answer:</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">2. How much does your services cost?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">Our pricing depends on the specific
                        services required by each client. We offer competitive and affordable pricing, and
                        we are committed to providing transparent and upfront pricing.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">3. How do I get started with your services? 
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">To get started with our
                        services, you can contact us through our website, phone, or email. Our team will
                        be happy to discuss your needs and provide you with a customized solution.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">4. How long does it take to complete a tax filing or other service?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">The time
                        required to complete a tax filing or other service depends on the complexity of
                        the task and the specific requirements of each client. We work efficiently to
                        ensure that our clients&#39; needs are met in a timely manner.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">5. What if I have questions or concerns during the process?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer"> We are
                        committed to providing exceptional customer service, and our team is always
                        available to answer any questions or concerns that you may have during the
                        process. We strive to ensure that our clients are fully satisfied with our services.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">6. Is my data and information secure with your company?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">Yes, we take
                        data security and confidentiality very seriously. We have adopted international
                        industry standards for data security, and we ensure that our clients&#39; data and
                        information are kept safe and confidential at all times.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">7. What makes your company different from other tax consulting firms?</h3>
                        <span class="faq-t"></span>
                    </div>
                    <p class="answer"> We
                        differentiate ourselves from other tax consulting firms by providing customized
                        solutions tailored to each client&#39;s specific needs, using the latest technology to
                        ensure efficient and effective services, offering transparent and upfront pricing,
                        and providing exceptional customer service.</p>
                </div>

                <!-- <div class="topic">
                    <div class="open">
                        <h3 class="question">8. How can i find out personal information of another user?</h3>
                        <span class="faq-t"></span>
                    </div>
                    <p class="answer">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<app-contactform></app-contactform>