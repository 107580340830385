<app-navbar-one></app-navbar-one>

<div class="service-title" style="background-image: url(assets/img/pattern.png);opacity: 0.9;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Service Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Service Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row" *ngFor="let item of serviesdetails">
            <ng-container *ngIf="selectedID == item.id ">
                <div class="col-lg-4">
                    <!-- <div class="blog-search">
                    <form>
                        <input type="text" placeholder="Search">
                        <button><i class="flaticon-search"></i></button>
                    </form>
                </div> -->

                    <div class="service-category">
                        <h3>Services</h3>

                        <ul>

                            <li *ngFor="let item of serviesdetails"><a [ngClass]="{active:item.id == selectedID}"
                                    (click)="servicedetail(item.id)">{{item.heading}} <i class="icofont-arrow"></i></a>
                            </li>


                            <!-- <li><a routerLink="/service-details" (click)="servicedetail('1')">Goods & Services Tax <i
                                        class="icofont-arrow"></i></a>
                            </li>
                            <li><a routerLink="/service-details" (click)="servicedetail('2')">Income Tax <i
                                        class="icofont-arrow"></i></a></li>
                            <li><a routerLink="/service-details" (click)="servicedetail('3')">E-Way Bills<i
                                        class="icofont-arrow"></i></a></li>
                            <li><a routerLink="/service-details" (click)="servicedetail('4')">Tax Deduction at Source <i
                                        class="icofont-arrow"></i></a></li>
                            <li><a routerLink="/service-details" (click)="servicedetail('id')">Bank Loans<i
                                        class="icofont-arrow"></i></a></li>
                            <li><a routerLink="/service-details" (click)="servicedetail('id')">Accounting<i
                                        class="icofont-arrow"></i></a></li>
                            <li><a routerLink="/service-details" (click)="servicedetail('id')">MCA Services<i
                                        class="icofont-arrow"></i></a></li>
                            <li><a routerLink="/service-details" (click)="servicedetail('id')">Foreign Direct
                                    Investments <i class="icofont-arrow"></i></a></li>
                            <li><a routerLink="/service-details" (click)="servicedetail('id')">Miscellaneous Services<i
                                        class="icofont-arrow"></i></a>
                            </li>
                            <li><a routerLink="/service-details" (click)="servicedetail('id')">Savdhan Businessman<i
                                        class="icofont-arrow"></i></a>
                            </li>
                            <li><a routerLink="/service-details" (click)="servicedetail('id')">Payroll Services<i
                                        class="icofont-arrow"></i></a></li> -->


                        </ul>

                      
                    </div>
                   
                </div>
                <div class="col-lg-8">
                    <div class="service-details-area">
                       
                        <!-- <h2>GST-BHASWA Tax Return Seva Kendra</h2> -->
                        <h1 style="font-weight: 700;">{{item.heading}}</h1>
                        <div class="service-details-img">
                            <img class="img-full"
                            [src]="item.serviceImg">
                        <!-- <img src="assets/img/service-details.jpg" alt="service details">  -->
                        </div>
                        <p>GST-Bhaswa is a fully automated BPO, first of its kind in the Country that provides pan-India
                            CA guided, Audit of every transaction, ZERO-ERROR GST Returns Filing Service starting @
                            300/- Per Return. </p>
                        <p>Whether you are in Kashmir or Kanyakumari, Kutch or Kibithu, GST-Bhaswa is platform ready to
                            serve you.
                        </p>
                        <div [innerHTML]="item.servicelist"></div>

                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                    <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/img/service-details-2.jpg" class="service-btm-img" alt="service details">
                        </div>

                        <div class="col-lg-6">
                            <img src="assets/img/service-details-3.jpg" class="service-btm-img" alt="service details">
                        </div>
                    </div> -->

                        <!-- <ul>
                        <li>GST Health Check</li>
                        
                        <li>(Analysis of past &amp; pending issues)</li>
                        
                        <li>Resolution of Pending Problem</li>
                        <li>GST Return filing for all Categories of Tax Payers</li>
                        <li>Tax Loss Detection</li>
                        <li>Lost Tax Recovery Services</li>
                        <li>ZERO TAX Leakage</li>
                        <li>
                        (Identification of Unclaimed Taxes)</li>
                        
                        <li>New GST Registration</li>
                        <li>GST Annual returns</li>
                        <li>GST Audit</li>
                        <li>Fake GSTIN identification</li>
                        <li>GST Dept Show Cause Notice Reply &amp; Problem Resolution</li>
                        <li>GST tax planning</li>
                          <li>GST-EWB Reconciliation<br /><br /></li>
                        
                        </ul> -->

                    </div>
                    <div class="theme-button">
                        <a routerLink="/contact" class="default-btn">Apply Now</a>
                     </div>
                </div>



            </ng-container>

        </div>
    </div>
</section>