<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>

                    <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" id="name" formControlName="name">
                                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">

                                <div class="form-group mb-3">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control" id="email" formControlName="email" >
                                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                                        <div *ngIf="email.errors.required">Email is required.</div>
                                        <div *ngIf="email.errors.email">Email is invalid.</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12 col-sm-6">

                                <div class="form-group mb-3">
                                    <label for="Phone">Phone</label>
                                    <input type="number" class="form-control" id="phone" formControlName="phone">
                                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="text-danger">
                                        <div *ngIf="phone.errors.required">phone number is required.</div>
                         
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="message">Message</label>
                                    <textarea class="form-control" id="message" formControlName="message"
                                        row="10"></textarea>
                                    <div *ngIf="message.invalid && (message.dirty || message.touched)"
                                        class="text-danger">
                                        <div *ngIf="message.errors.required">Message is required.</div>
                                        <div *ngIf="message.errors.minlength">Message must be at least 10 characters
                                            long.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn"
                                    [disabled]="!contactForm.valid">Submit</button>
                                <div id="form-status"></div>
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>