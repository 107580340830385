import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError } from "rxjs/internal/operators/catchError";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private zone: NgZone,
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const path: any = req.url.split('/');

    let temp = [];
    let url = path[path.length - 1];
    let url1 = path[path.length - 2];

    temp.push(url);
    temp.push(url1);

    const IgnoreUrls = ['Token', 'pincode'];

    let count = 0;
    for (let i = 0; i < temp.length; i++) {
      let a = temp[i];
      let request = (IgnoreUrls.includes(a));
      request == false ? count = count + 1 : "";
    }

    if (count == temp.length) {
      const token = localStorage.getItem('GST_Token');
      req = req.clone({
        setHeaders: { Authorization: 'bearer ' + token }
      });
    }
    return next.handle(req).pipe(
      catchError(err => {
        this.zone.run(() => {
          // this.CF.toasterError('Session Expired', 'Error!');
          // this.CF.ToastError('Session Expired', 'Error')
          // this.router.navigate(['/']);
          this.router.navigate(['/']);
          // localStorage.removeItem(this.CF.tokenname);
        })
        return throwError(err);
      }));
  };
}
