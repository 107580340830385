<app-navbar-one></app-navbar-one>

<div class="service-title" style="background-image: url(assets/img/pattern.png);opacity: 0.9;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Services</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Services</li>
                </ul>
            </div>
        </div>
    </div>
</div>


<section class="features-section pb-70 pt-100">
    <div class="container">
        <div class="section-title text-center">
            <span>
              Services Offered 
                </span>
            <h2>
                GST-BHASWA Tax Return Seva Kendra  
                </h2>
            <!-- <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">

                <div class="features-card feature-one"  (click)="servicedetail('1')" >
                    <i class="flaticon-technical-support"></i>
                    <h3>Goods & Services Tax</h3>
                    <!-- <p>GST is a comprehensive tax system that has simplified the tax structure in India. To avail the benefits of GST, businesses need to comply with the rules and regulations laid down by the government</p> -->
                   
                   
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-one"  (click)="servicedetail('2')" >
                    <i class="flaticon-technical-support"></i>
                    <h3>Income Tax</h3>
                    <!-- <p>GST-Bhaswa offers online tax filing services for businesses and individuals, ensuring accurate and timely filing to avoid penalties and legal issues.</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-two" (click)="servicedetail('3')">
                    <i class="flaticon-megaphone"></i>
                    <h3>E-Way Billsn</h3>
                    <!-- <p>The company assists clients in registering for GST, providing guidance and support throughout the process to ensure compliance with regulations.</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details"   class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-three"  (click)="servicedetail('4')" >
                    <i class="flaticon-optimization"></i>
                    <h3>Tax Deduction at Source</h3>
                    <!-- <p>GST-Bhaswa helps businesses register their trademarks, protecting their brand identity and preventing infringement</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-four "  (click)="servicedetail('5')" >
                    <i class="flaticon-first-place"></i>
                    <h3>Bank Loans</h3>
                    <!-- <p> The company provides bookkeeping and outsourcing services, freeing up businesses' time and resources to focus on core operations..</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-five" (click)="servicedetail('6')" >
                    <i class="flaticon-alarm-clock"></i>
                    <h3>Accounting</h3>
                    <!-- <p> GST-Bhaswa helps businesses set up and register their companies, providing advice and guidance on legal and regulatory requirements</p> -->
                    <!-- <div class="theme-button mt-2"> <a routerLink="/service-details"  class="default-btn2">Know More</a></div> -->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six"  (click)="servicedetail('7')">
                    <i class="flaticon-newsletter"></i>
                    <h3>MCA Services</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">   <a routerLink="/service-details"  class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six"  (click)="servicedetail('8')" >
                    <i class="flaticon-newsletter"></i>
                    <h3>Foreign Direct Investments</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">   <a routerLink="/service-details" class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six" (click)="servicedetail('10')" >
                    <i class="flaticon-newsletter"></i>
                    <h3>Savdhan Businessman</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">  <a routerLink="/service-details"  class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six" (click)="servicedetail('9')">
                    <i class="flaticon-newsletter"></i>
                    <h3>Miscellaneous Services</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">  <a routerLink="/service-details"   class="default-btn2">Know More</a></div> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six" (click)="servicedetail('11')">
                    <i class="flaticon-newsletter"></i>
                    <h3>Payroll Services</h3>
                    <!-- <p>The company assists businesses with Foreign Direct Investment (FDI) compliance, ensuring they meet all necessary regulatory requirements.</p> -->
                    <!-- <div class="theme-button mt-2">    <a routerLink="/service-details"   class="default-btn2">Know More</a></div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- 
<div class="video-style-two">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                    <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i class="bx bx-play"></i></a>
                    <p>Watch our video</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Portfolio</span>
            <h2>Latest Works For Clients</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/1.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Online Business</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/2.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Digital Marketing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/3.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Business Research</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/4.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Content Writing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/5.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/6.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>SEO Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="theme-button text-center">
            <a routerLink="/portfolio" class="default-btn">Load More</a>
        </div>
    </div>
</section> --><app-contactform></app-contactform>